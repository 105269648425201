@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/variables';

.component {
  position: relative;
  overflow: hidden;
}

.header {
  margin-top: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $smallPhonesBreakPoint) {
    margin-top: 14px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 24px 0 30px;
  row-gap: 10px;

  @media (max-width: $smallPhonesBreakPoint) {
    padding: 14px 0 20px;
    row-gap: 6px;
  }

  &:after {
    content: "_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _";
    color: $-gray2;
    letter-spacing: 3px;
    width: 370px;
    position: absolute;
    bottom: -2px;
    left: -2.5px;
    white-space: nowrap;

    @media (max-width: $phonesBreakPoint) {
      left: 0px;
    }
  }
}

.amount {
  color: $-black;

  @include boldText();

  @media (max-width: $smallPhonesBreakPoint) {
    font-size: 24px;
  }
}

.dueDate {
  color: $-gray;

  @include mediumText();

  &.overdue {
    color: $-red;
  }
}

.description:not(:last-child):after {
  content: '; ';
}
