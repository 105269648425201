@import 'styles/colors';
@import 'styles/mixins';

.component {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 124px;
}

.logo {
  margin: 24px 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $-black;
}

.title {
  margin-bottom: 10px;

  @include boldText(20px);
}

.text {
  text-align: center;
  
  @include normalText(14px, 20px);
}
