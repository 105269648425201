@import 'styles/colors';
@import 'styles/mixins';

.component {
  display: flex;
  line-height: 20px;

  @include normalText(14px, 20px);
}

.title {
  color: $-gray;
  min-width: 66px;
}
