@import 'styles/colors';
@import 'styles/mixins';

.component {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  width: 172px;
  height: 34px;
  border-radius: 4px;
  border: none;
  background-color: $-white;
  outline: none;

  @include mediumText();

  &.primary {
    color: $-white;
    background-color: $-blue2;

    &:hover {
      opacity: 0.7;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
