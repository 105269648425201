@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: local('Inter'), url(../assets/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: local('Inter'), url(../assets/fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: local('Inter'), url(../assets/fonts/Inter-SemiBold.ttf) format('truetype');
}
