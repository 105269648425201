@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/variables';

.component {
  display: flex;
  flex-direction: column;
  position: relative;

  @include normalText();
}

.icon {
  min-width: 16px;
  min-height: 16px;
}

.feeDescription {
  display: flex;
  align-items: center;
  margin-top: 14px;
  column-gap: 10px;

  @include normalText(14px, 20px);
}

.cardRadioButton {
  margin-top: 14px;
}

.paymentButton {
  margin: 30px 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  border: none;
  outline: none;
  color: $-white;
  padding: 13px;
  background-color: $-blue2;
  cursor: pointer;

  @include normalText(16px);

  &:hover {
    opacity: 0.7;
  }
}

.footer {
  color: $-gray;

  @media (max-width: $phonesBreakPoint) {
    text-align: center;
  }
}

.loading {
  pointer-events: none;
}
