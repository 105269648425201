@import 'styles/colors';
@import 'styles/variables';

.component {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;
}

.background {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $-white2;
  position: absolute;
  overflow: hidden;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  top: 0;
  left: 0;
  width: 100%;
  height: 552px;
  z-index: 0;

  @media (max-width: $phonesBreakPoint) {
    height: 425px;
  }
}

.content {
  position: relative;
  z-index: 1;
}
