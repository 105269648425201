@import 'styles/colors';
@import 'styles/mixins';

.component {
  color: $-white;
  border-radius: 24px;
  padding: 4px 14px;

  @include normalText(15px);

  &.green {
    background-color: $-green;
  }

  &.red {
    background-color: $-red;
  }

  &.blue {
    background-color: $-blue2;
  }
}
