@import 'styles/variables';
@import 'styles/mixins';

.component {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 124px;
  padding-bottom: 40px;

  @media (max-width: $phonesBreakPoint) {
    padding-top: 72px;
  }

  @media (max-width: $phonesBreakPoint) {
    padding-bottom: 20px;
  }
}

.paymentCardContent {
  min-height: 330px;

  @media (max-width: $smallPhonesBreakPoint) {
    min-height: 262px;
  }
}

.spinner {
  @include positionAbsoluteWithCentering()
}

.content {
  margin-top: 30px;
  
  @media (max-width: $phonesBreakPoint) {
    margin-top: 24px;
  }
}
