@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/variables';

.component {
  @include normalText(14px, 20px);
}

.support {
  display: flex;
  column-gap: 10px;
  color: $-black;
  margin-bottom: 24px;
  align-items: center;
}

.icon {
  width: 28px;
}

.button {
  height: 48px;
  width: 100%;
}

.link {
  color: $-blue;
  text-decoration: none;
}
