@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/variables';

.component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  width: 344px;
  max-width: 100%;
  border-radius: 6px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $-white;
  padding: 48px;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  min-height: 260px;

  @media (max-width: $phonesBreakPoint) {
    max-width: 300px;
    padding: 42px 22px;
    border-radius: 24px;
  }

  @media (max-width: $smallPhonesBreakPoint) {
    max-width: 240px;
    padding: 22px 16px;
  }
}

.contacts {
  padding-top: 28px;
  margin-bottom: 40px;
  text-align: center;

  @include normalText(12px);

  @media (max-width: $phonesBreakPoint) {
    padding: 28px 32px 0;
  }
}

.link {
  color: $-blue;
  margin: 0 4px;
}

.logo {
  max-width: 100%;
}
