$-black: #333333;
$-blue: #1eaae2;
$-blue2: #27a9e0;
$-blue3: #0373B7;
$-gray: #858585;
$-gray2: #c2c8d0;
$-green: #2abb11;
$-red: #eb5757;
$-white: #ffffff;
$-white2: #F5F7F9;

