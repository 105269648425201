@import 'styles/fonts';

@mixin boldText($fontSize: 34px) {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: $fontSize;
}

@mixin mediumText($fontSize: 14px, $lineHeight: 22px) {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: $fontSize;
  line-height: $lineHeight;
}

@mixin normalText($fontSize: 12px, $lineHeight: 14px) {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: $fontSize;
  line-height: $lineHeight;
}

@mixin positionAbsoluteWithCentering() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
