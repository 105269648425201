@import 'styles/colors';

.component {
  position: absolute;
}

.spinner {
  position: relative;
  height: 64px;
  width: 64px;
  border: 4px solid transparent;
  border-top-color: $-blue3;
  border-left-color: $-blue3;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;

  &::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border: 4px solid transparent;
    border-top-color: $-blue2;
    border-left-color: $-blue2;
    border-radius: 50%;
    animation: spinBack 1s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinBack {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-720deg);
  }
}
