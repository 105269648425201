@import 'styles/colors';
@import 'styles/mixins';

.component {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $-white;
}

.rights {
  padding-bottom: 36px;
  text-align: center;
  color: $-gray;

  @include normalText(14px);
}
