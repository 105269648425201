@import 'styles/colors';
@import 'styles/mixins';

.component {
  display: flex;
  align-items: center;
  position: relative;
  column-gap: 9px;
  height: 18px;
  color: $-gray;

  @include normalText(15px);
}

.radio {
  border: 1px solid $-blue2;
  border-radius: 50%;
}

.component,
.radioButton {
  cursor: pointer;
}

.radioButton {
  width: 17px;
  height: 17px;
  position: relative;
  margin: 0 5px 0 0;

  &:after {
    background-color: $-white;
    border: 1px solid $-gray;
  }

  &:checked {
    &:after {
      border-color: $-blue2;
    }

    &:before {
      width: 10px;
      height: 10px;
      left: 4px;
      top: 4px;
      background-color: $-blue2;
      z-index: 3;
    }

    & ~ * {
      color: $-blue2;
    }
  }

  &:checked:after,
  &:after {
    width: 16px;
    height: 16px;
    left: 0;
    right: 0;
  }

  &:checked:before,
  &:checked:after,
  &:after {
    content: '';
    border-radius: 50%;
    position: absolute;
  }
}
