@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/variables';

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: $-white;
  min-height: 100%;
}

a {
  text-decoration: none;
}

#AcceptUIContainer {
  @media (max-width: $phonesBreakPoint) {
    // force to change styles which set from js
    margin-left: 0 !important;
    top: 50% !important;
    transform: translateY(-50%);
  }
}
